import React from "react";

const noOp = () => null;

const SetValueContext = React.createContext({
  value: null,
  setValue: noOp
})

export default SetValueContext
