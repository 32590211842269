import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>
    {
      element
    }
  </GlobalContextProvider>;
};

//Duplicate code in gatsby-ssr.js
