import React, {useReducer} from "react"
import SetValueContext from './SetValueContext'

const reducer = (state, {type, payload}) => {
  switch (type) {
    case 'SET_VALUE': {
      const {key, value} = payload;

      return {
        ...state,
        [key]: value
      }
    }
    default:
      return state
  }
};

const GlobalContextProvider = ({ children }) => {
  const [contextData, dispatch] = useReducer(reducer, {});

  return (
    <SetValueContext.Provider value={{
      contextData,
      setContextValue: (key, value) => dispatch({
        type: 'SET_VALUE',
        payload: {
          key,
          value
        }
      })
    }}>
      {
        children
      }
    </SetValueContext.Provider>
  )
}

export default GlobalContextProvider
